import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';

const getInitState = () => {
  return {
    siteInfo:{},
    steps: {
      current: "LoanPurpose_Mortgage",
      historyList: [],
    },
    questionData1: [],
    questionData2: [],
    questionAnswer: {},
    leadCode: "",
    leadCode_copy: "",
    adMsg:[],
    isInternalNavigation: false,
    activeIndex: 0,
    userIp: "",
    formStayTime: {
      startTime: 0,
      endTime: 0
    },
    queryIdObj:{}
  }
}

export default createStore({
  state: getInitState(),
  getters: {
  },
  mutations: {
    SET_SITEINFO: (state, siteInfo) => {
      state.siteInfo = siteInfo
    },
    SET_STEPS_current: (state, current) => {
      state.steps.current = current
      // Cookies.set('current', current)
    },
    SET_STEPS_historyList: (state, historyItem) => {
      // console.log(1111,state.steps.historyList);
      if (Array.isArray(historyItem)) {
        state.steps.historyList = historyItem
        return
      }
      if(state.steps.historyList.every((sub)=>sub!==historyItem)){
        // console.log(2222);
        state.steps.historyList.push(historyItem)
      }
      
    },
    SET_QUESTION_data1: (state, questionData1) => {
      state.questionData1 = questionData1
    },
    SET_QUESTION_data2: (state, questionData2) => {
      state.questionData2 = questionData2
    },
    SET_QUESTION_answer: (state, answerObj) => {
      state.questionAnswer[answerObj.questionKey] = answerObj.answer
    },
    SET_LEADCODE: (state, leadCode) => {
      state.leadCode = leadCode
    },
    SET_LEADCODE_COPY: (state, leadCode_copy) => {
      state.leadCode_copy = leadCode_copy
    },
    SET_AdMsg: (state, adMsg) => {
      state.adMsg = adMsg
    },
    RESET_STATE: (state) => {
      const initState = getInitState()
      Object.keys(initState).forEach(key => {
        if (key !== 'questionData1' && key !== 'questionData2' && key !== "siteInfo" && key !== "isInternalNavigation" && key !== "leadCode_copy" && key !== 'adMsg'&& key !== 'queryIdObj' && key !=='userIp') {
          if (key === "steps") {
            state[key].current = initState[key].current
          } else{
            state[key] = initState[key]
          }
        }
      })
    },
    SET_IsInternalNavigation: (state, isInternalNavigation) => {
      state.isInternalNavigation = isInternalNavigation
    },
    SET_ActiveIndex: (state, activeIndex) => {
      state.activeIndex = activeIndex
    },
    SET_UserIp: (state, userIp) => {
      state.userIp = userIp
    },
    SET_FormStayTime_Start: (state, startTime) => {
      state.formStayTime.startTime = startTime
    },
    SET_FormStayTime_End: (state, endTime) => {
      state.formStayTime.endTime = endTime
    },
    SET_QueryIdObj: (state, queryIdObj) => {
      state.queryIdObj = queryIdObj
    },
  },
  actions: {
    setSiteInfo({ commit }, siteInfo) {
      commit('SET_SITEINFO', siteInfo)
    },
    setStepsCurrent({ commit }, current) {
      commit('SET_STEPS_current', current)
    },
    setStepsHistoryList({ commit }, historyItem) {
      commit('SET_STEPS_historyList', historyItem)
    },
    setQuestionData1({ commit }, questionData1) {
      commit('SET_QUESTION_data1', questionData1)
    },
    setQuestionData2({ commit }, questionData2) {
      commit('SET_QUESTION_data2', questionData2)
    },
    setQuestionAnswer({ commit }, answerObj) {
      commit('SET_QUESTION_answer', answerObj)
    },
    setLeadCode({ commit }, leadCode) {
      commit('SET_LEADCODE', leadCode)
    },
    setLeadCode_Copy({ commit }, leadCode_copy) {
      commit('SET_LEADCODE_COPY', leadCode_copy)
    },
    setAdMsg: ({ commit }, adMsg) => {
      commit('SET_AdMsg', adMsg)
    },
    resetState({ commit }) {
      commit('RESET_STATE')
    },
    setIsInternalNavigation({ commit }, isInternalNavigation) {
      commit('SET_IsInternalNavigation', isInternalNavigation)
    },
    setActiveIndex({ commit }, activeIndex) {
      commit('SET_ActiveIndex', activeIndex)
    },
    setUserIp({ commit }, userIp) {
      commit('SET_UserIp', userIp)
    },
    setFormStayTime_start({ commit }, startTime) {
      commit('SET_FormStayTime_Start', startTime)
    },
    setFormStayTime_end({ commit }, endTime) {
      commit('SET_FormStayTime_End', endTime)
    },
    setQueryIdObj({ commit }, queryIdObj) {
      commit('SET_QueryIdObj', queryIdObj)
    },
  },
  modules: {
  },
  plugins: [createPersistedState({
    key: 'landingStore',
  })]
})
