import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/fonts/fonts.css'
import '@/assets/css/base.scss'
// import 'vuetify/styles'
// import { createVuetify } from 'vuetify'
// import * as components from 'vuetify/components'
// import * as directives from 'vuetify/directives'
import App from './App.vue'
import router from './router'
import store from './store'
import Trim from "./utils/directive/trim";


// const vuetify = createVuetify({
//   components,
//   directives
// })

function setRem() {
    
    const screenWidth = 1920
    const scale = screenWidth / 16
    const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
    
    const htmlDom = document.getElementsByTagName('html')[0]
    
    htmlDom.style.fontSize = htmlWidth / scale + 'px'
    // console.log(777,htmlDom.style.fontSize);
    
  }
//   setRem()
  
//   window.onresize = function() {
//     setRem()
//   }
const app = createApp(App)

// app.config.errorHandler = (err, instance, info) => {
//     console.error('Vue全局错误捕获:', err);
//     // 在这里记录错误或者处理错误
// };
app.use(store)
// app.use(vuetify)
app.use(router)
app.use(ElementPlus)
app.mount('#app')

app.directive("trim", Trim)
